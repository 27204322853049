import axios from "../axios";
export default {
    async test() {
        return axios.post('/app/user/xxx', {
            params: {
                test: '',
            },
        })
    },
    async fetchASUsers() {
        const { data } = await axios.get('/admin/user/list', {
            params: {
                current: 1,
                size: 500,
            },
        })
        console.log('api:fetchASUsers ::>>>', data);
        return data.records.filter(item => item.sys_roles[0]?.id === 17 || item.sys_roles[0]?.id === 6);
    },

    async fetchSpace(info, isSA) {
        const { code, message, data } = await axios.post(`/space/manage/${isSA ? 'spaceList' : 'details'}`, info);
        console.log('api:fetchSpace ::>>>', code, message, data);
        return { code, message, data };
    },
    async fetchAllSpace(info) {
        const { code, message, data } = await axios.post(`/space/manage/spaceList`, info);
        console.log('api:fetchSpace ::>>>', code, message, data);
        return { code, message, data };
    },
    async fetchOwnSpace(info) {
        const { code, message, data } = await axios.post('/space/manage/details', info);
        console.log('api:fetchSpace ::>>>', code, message, data);
        return { code, message, data };
    },
    async createSpace(info) {
        const { code, message } = await axios.post('/space/manage/create', info);
        console.log('api:createSpace ::>>>', code, message);
        return {code, message};
    },

    async updateSpace(info) {
        const { code, message } = await axios.post('/space/manage/update', info);
        console.log('api:createSpace ::>>>', code, message);
        return {code, message};
    },

    async deleteSpace(info) {
        const { code, message } = await axios.post('/space/manage/delete', info);
        console.log('api:createSpace ::>>>', code, message);
        return {code, message};
    },

    async fetchGroup(info) {
        const { code, message, data } = await axios.post('/group/manage/groupList', info);
        console.log('api:fetchGroup ::>>>', code, message, data);
        return { code, message, data };
    },
    async createGroup(info) {
        const { code, message } = await axios.post('/group/manage/create', info);
        console.log('api:createGroup ::>>>', code, message);
        return {code, message};
    },
    async updateGroup(info) {
        const { code, message } = await axios.post('/group/manage/update', info);
        console.log('api:updateGroup ::>>>', code, message);
        return {code, message};
    },
    async deleteGroup(info) {
        const { code, message } = await axios.post('/group/manage/delete', info);
        console.log('api:updateGroup ::>>>', code, message);
        return {code, message};
    },
    async addUser2Group(info) {
        const { code, message } = await axios.post('/group/manage/allocation', info);
        console.log('api:updateGroup ::>>>', code, message);
        return {code, message};
    },
    async addCategory2Group(info) {
        const { code, message } = await axios.post('/group/manage/perm', info);
        console.log('api:updateGroup ::>>>', code, message);
        return {code, message};
    },

    async fetchUser(info) {
        const { code, message, data } = await axios.post('/app/user/list', info);
        console.log('api:fetchUser ::>>>', code, message, data);
        return { code, message, data };
    },
    async createUser(info) {
        const { code, message } = await axios.post('/app/user/save', info);
        console.log('api:createUser ::>>>', code, message);
        return {code, message};
    },
    async userRepass(info) {
        const { code, message } = await axios.post('/app/user/repass', info);
        console.log('api:userRepass ::>>>', code, message);
        return {code, message};
    },
    async deleteUser(info) {
        const { code, message } = await axios.post('/app/user/delete', info);
        console.log('api:userRepass ::>>>', code, message);
        return {code, message};
    },


    //  prompt
    async fetchCategoryList(info) {
        const { code, message, data } = await axios.post('/app/category/categoryList', info);
        console.log('api:userRepass ::>>>', code, message);
        return {code, message, data};
    },
    async createCategory(info) {
        const { code, message } = await axios.post('/app/category/create', info);
        console.log('api:userRepass ::>>>', code, message);
        return {code, message};
    },
    async updateCategory(info) {
        const { code, message } = await axios.post('/app/category/update', info);
        console.log('api:userRepass ::>>>', code, message);
        return {code, message};
    },
    async deleteCategory(info) {
        const { code, message } = await axios.post('/app/category/delete', info);
        console.log('api:userRepass ::>>>', code, message);
        return {code, message};
    },
    async addPrompt2Category(info) {
        const { code, message } = await axios.post('/app/category/allocation', info);
        console.log('api:userRepass ::>>>', code, message);
        return {code, message};
    },



    async fetchPromptList(info) {
        const { code, message, data } = await axios.post('/app/prompt/list', info);
        console.log('api:userRepass ::>>>', code, message);
        return {code, message, data};
    },
    async createPrompt(info) {
        const { code, message, data } = await axios.post('/app/prompt/save', {'save_mask_forms': info});
        console.log('api:userRepass ::>>>', code, message);
        return {code, message, data};
    },
    async updatePrompt(info) {
        const { code, message, data } = await axios.post('/app/prompt/update', {'update_mask_forms': info});
        console.log('api:userRepass ::>>>', code, message);
        return {code, message, data};
    },
    async deletePrompt(info) {
        const { code, message, data } = await axios.post('/app/prompt/delete', info);
        console.log('api:userRepass ::>>>', code, message);
        return {code, message, data};
    },

}